
.header h1 {
    margin:0;
    font-size: 165%;
    color: black;
    text-decoration: none;
}

.header h3 {
    margin:0;
    font-weight: 100;
    position: relative;
    bottom: 15px;
    font-size: 80%;
    margin-left: 20px;
}

.navbar{
    position: absolute;
    top: 4%;
    right: 5%;
    font-weight: bold;
}

.navbtn {
    margin-left:15px;
    color:black;
}

.header img {
    width: 30px;
    border-radius: 100px;
}

.selectedbtn {
    text-decoration: underline;
}

.footer {
    font-size: 80%;
}
@media (max-width: 991px) {
    
  }
  
  @media (max-width: 480px) {
    .header h1 {
        font-size: 140%;
    }
    .navbar{
        top: 7.5%;
        right: 5%;
    }
    .header h3 {
        margin-left: -10px;
    }
    
  }