.open-img {
    width: 65%;
}

@media (max-width: 991px) {
    
}

@media (max-width: 480px) {
    .open-img {
        width: 100%;
    }
}