.novel-details {
    float: right;
    width: 45%;
    text-align: center;
    padding-bottom: 5%;
  }

  .novel-details h1 {
    margin: 0;
    font-size: 120%;
  }

  .novel-details .novel-desc {
    width: 100%;
    overflow-wrap: normal;
    display: inline-block;
    font-size: 90%;
  }

.cover-img {
    width: 45%;
}

.chapters-title {
    font-weight: bold;
    font-size: 120%;
}



.chapters {
    margin:1% ;
}

.chapter-item {
    width: 55%;
    border-style: solid;
    margin-bottom:15px;
    border-width: 2px;
    height: 55px;
    overflow: hidden;
}

.chapter-item:hover {
    cursor: pointer;
    background-color: lightgray;
}

.chapter-item span {
    margin: 10px;
}

.chapter-item img {
    float: left;
    width: 55px;
}

.no-link{
    background-color: lightgray;
}
.no-link:hover  {
cursor: default;
}
@media (max-width: 991px) {
    
}

@media (max-width: 480px) {
    .novel-details {
        float: left;
        width: 100%;
      }  
      .novel-pages img {
        width: 100%;
    }
    .chapter-item {
        width: 100%;
        font-size: 90%;
    }

    .chapter-item img {
        float: left;
        width: 55px;
    }
}