.popup {
    position: fixed;
    z-index: 3;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    backdrop-filter: blur(10px);
    text-align: center;
}


.close-btn {
    position: absolute;
    font-weight: 100;
    top: 16px;
    right: 16px;
    background-color: transparent;
    border-width: 0px;
    font-size: 105%;
}

.close-btn:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition-delay: 2ms;
}