.gallery-item {
    margin: 5%;
    width: 70%;
    text-align: center;
}
.gallery-item img {
    width: 100%;
    object-fit: cover;
    height: 240px;
}

.gallery-item img:hover {
    width: 105%;
    transition: 0.8s;
    cursor: pointer;
}

.gallery-item .item-title {
    font-weight: bold;
    width: 100%;
    position: relative;
    bottom: 10px;
}

.responsive-gallery {
    display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin:1%;
}
@media (max-width: 991px) {
    .responsive-gallery {
      grid-template-columns: repeat(3, 1fr);
    }
    .gallery-item img {
        height: 180px;
    }
}

@media (max-width: 480px) {
    .responsive-gallery {
        grid-template-columns: repeat(2, 1fr);
      }
      .gallery-item img {
        height: 140px;
    }
  
}