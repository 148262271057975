.comic-details {
    float: right;
    width: 45%;
    text-align: center;
    padding-bottom: 5%;
  }

  .comic-details h1 {
    margin: 0;
    font-size: 120%;
  }

  .comic-details .comic-desc {
    width: 65%;
    overflow-wrap: normal;
    display: inline-block;
    font-size: 90%;
  }

.comic-pages img {
    width: 55%;
}



.comic {
    margin:1% ;
}

.next-chapters {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.slider-img {
    width: 55%;
    padding: 1.5px;
    height: 210px;
}

.slider-img:hover{
  width: 60%;
  transition: 0.8s;
  cursor: pointer;
}

.selected-img {
  border-style: solid;
}

.swiper {
  position: relative;
  left: 50%;
  width: 550%;
}

.chapter-index {
  font-weight: bold;
  text-align: center;
}

.no-link-slid:hover{
  cursor: default;
  width: 55%;
}

@media (max-width: 991px) {
  .slider-img {
    height: 121px;
}
}

@media (max-width: 480px) {
    .comic-details {
        float: left;
        width: 100%;
      }  
      .comic-pages img {
        width: 100%;
    }
    .slider-img {
      height: 51px;
  }
  .swiper {
    font-size: 75%;
  }
}