.about-img {
    width: 30%;
}

.about-title {
    font-weight: bold;
}

.mailing-container {
    float: right;
}

.mailing-container-1 {
    display: none;
}
.about-desc {
    width: 65%;
    overflow-wrap: normal;
    display: inline-block;
  }

  @media (max-width: 991px) {
    
}

@media (max-width: 480px) {
    .about-img {
        width: 80%;
    }
    .about-desc {
        width: 100%;
      }

      .mailing-container {
        display: none;
    }

    .mailing-container-1 {
        display: block;
    }
}