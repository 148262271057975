@import url('https://fonts.googleapis.com/css?family=Annie+Use+Your+Telescope');

body {
  font-family: "Annie Use Your Telescope", cursive;
  background: url("background.jpg");
  background-repeat: no-repeat;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1.5%;
  font-size: 150%;
}

.spinner {
  width: 100%;
  align-items: center;
  text-align: center;
  padding-left: 50%;
}
@media (max-width: 991px) {
  body {
    background: url("background-mid.jpg");
  }
}

@media (max-width: 480px) {
  body {
    background: url("background-small.jpg");
  }
  .spinner {
    padding-left: 25%;
  }
}